import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, DatePicker, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';
import { getVehicleListReq } from '../../../actions/vehicle';
import { getParams } from '../../../routes/utils';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import {
  getEventLabel,
  getOriginLabel,
  parseDateGeneralStringFormat,
} from './log-utils';
import { getDriverLogListReq } from '../../../actions';
import { getTzValue } from '../../../utils/utils';

const { RangePicker } = DatePicker;

const App: React.FC = () => (
  <Space direction="vertical" size={12}>
    <RangePicker />
    <RangePicker showTime />
    <RangePicker picker="week" />
    <RangePicker picker="month" />
    <RangePicker picker="quarter" />
    <RangePicker picker="year" />
  </Space>
);

dayjs.extend(customParseFormat);

const timeFormat = (second: number) => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second % 3600) / 60);
  const seconds = second % 60;
  const addLeadingZero = (value: number): string => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const formattedTime = {
    hours: addLeadingZero(hours),
    minutes: addLeadingZero(minutes),
    seconds: addLeadingZero(seconds),
  };
  return formattedTime;
};
export const LogOriginalTab: React.FC = () => {
  const dispatch = useDispatch();
  const { handleTableChange, tableParams, rowSelection } = useTableParams({});
  const originalLogs = useSelector(
    (state: any) => state?.driverLog?.originalLogs,
  );
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const originalVehicals = useSelector(
    (state: any) => state?.driverLog?.originalVehicals,
  );
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const params = useParams();
  const count = useSelector((state: any) => state?.log?.count);
  const loading = useSelector((state: any) => state?.log?.loading);

  React.useEffect(() => {
    dispatch(
      getDriverLogListReq({
        queryParams: {
          with: ['driver_groups', 'vehicle', 'drivers', 'vehicle'],
        },
        driverid: params?.driverid,
        date: driverLogDate,
        original: true,
      }),
    );
  }, [driverLogDate]);

  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Date & Time',
      key: 'identificator_log',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>
            {dayjs(record.timestamp * 1000)
              .tz(tz)
              .format('MM/DD/YYYY HH:mm:ss')}
          </div>
        );
      },
      width: '18%',
      ellipsis: true,
    },
    {
      title: 'Duration',
      key: 'duration',
      dataIndex: 'duration',
      render: (name, record, index) => {
        const time = timeFormat(record?.duration);
        const label =
          time.hours.includes('00') &&
          time.minutes.includes('00') &&
          time.seconds.includes('00');

        return (
          <div>{!label && `${time.hours}:${time.minutes}:${time.seconds}`}</div>
        );
      },
      width: '12%',
      ellipsis: true,
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu">
            {getEventLabel(record?.event_type, record?.event_code)}
          </div>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.location ? `${record?.location}` : ''}
          </div>
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`${record?.annotations[0]?.text || ''}`}
          </div>
        );
      },
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      width: '9%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {originalVehicals?.identificator}
          </div>
        );
      },
    },

    {
      title: 'Odometer',
      dataIndex: 'total_miles',
      key: 'total_miles',
      width: '7%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`${defaultTo(record?.total_miles, '')}`}
          </div>
        );
      },
    },
    {
      title: 'EH',
      dataIndex: 'total_hours',
      key: 'total_hours',
      width: '5%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.total_hours?.toFixed(1)}
          </div>
        );
      },
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
      width: '6.8%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`${defaultTo(
              getOriginLabel(
                record?.event_type,
                record?.event_code,
                record?.record_origin,
              ),
              '',
            )}`}
          </div>
        );
      },
    },
  ];
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <div style={{ width: '100%' }} className="logs-table">
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={originalLogs}
              pagination={{
                ...tableParams.pagination,
                position: ['bottomCenter'],
                total: count,
              }}
              loading={loading}
              onChange={handleTableChange}
              rowSelection={{ ...rowSelection, columnWidth: 10 }}
              className="table-custom-original-tab"
              //   sticky
              //   scroll={{ y: window.innerHeight - 235 }}
            />
          </div>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
