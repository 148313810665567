import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCarriersListReq } from '../../../actions/carrier';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { carrierForm } from './carrier-form';
import { InputType } from '../../../constants/inputs';

import { createUnitReq } from '../../../actions/unit';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { cleanObject } from '../../../utils/cleanObject';
import MainContainer from '../../layout/MainContainer';

export const UnitCreatePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, driver, currentCarrier } = useSelector(
    (state: any) => state.driver,
  );

  const { checkPermission } = usePermissions();
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [initialValues, setInitialValues] = useState({
    carrier: null,
    driver: null,
    color: null,
    truck: null,
    device: null,
    trailer: null,
    codriver: null,
    // notice: "",
  });

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['settings', 'terminals', 'driver_groups'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...(!currentCarrier.defaultSavedCarrier ? currentCarrier?.settings : {}),
      cargo_type: form.getFieldValue('cargo_type'),
      driver_group: currentCarrier?.defaultSavedCarrier
        ? form.getFieldValue('driver_group')
        : null,
    });
  }, [currentCarrier]);
  const navigate = useNavigate();
  const handleSubmit = async (values: any) => {
    const data = cleanObject({
      ...values,
      color: null,
      // codriver: 0,
      // status: 1,
    });
    dispatch(
      createUnitReq({
        values: data,
        onSuccess: () => {
          form.setFieldsValue(initialValues);
        },
      }),
    );
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  return (
    <>
      {checkPermission(AllPermissionsType.DRIVER_CREATE) ? (
        <MainContainer half>
          <Row style={{ height: '100%' }}>
            {/* <Graph /> */}
            {false ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                >
                  {carrierForm({}).map((field: any, i: number) => {
                    if (field.type === InputType.ADD_DYNAMIC) {
                      return (
                        <CommonInput
                          currentIndex={currentIndex}
                          fields={fields}
                          key={i}
                          setCurrentIndex={setCurrentIndex}
                          {...field}
                          form={form}
                        />
                      );
                    }
                    return (
                      <CommonInput
                        key={i}
                        {...field}
                        form={form}
                        isDriverCreate={false}
                      />
                    );
                  })}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '85px', marginRight: 12 }}
                    >
                      Submit
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
