import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createCarrierReq, getCarriersListReq } from '../../../actions/carrier';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { carrierForm } from './carrier-form';
import { Graph } from '../../common/graph/Graph';
import { InputType } from '../../../constants/inputs';
import { getDocumentByType } from './constant';

import {
  updateDriverReq,
  getDriverReq,
  createDriverReq,
  setCurrentCarrier,
} from '../../../actions/driver';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { DriverField } from './constant';
import MainContainer from '../../layout/MainContainer';

export const DriverCreatePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, driver, currentCarrier } = useSelector(
    (state: any) => state.driver,
  );
  const navigate = useNavigate();

  const { checkPermission } = usePermissions();
  const { loading: carrierLoading, carrierList } = useSelector(
    (state: any) => state.carrier,
  );
  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    username: '',
    usdot: '',
    phone: '',
    mcnumber: '',
    email: '',
    person: '',
    status: 1,
    notes: '',
    email_second: '',
    cdl_expiration: '',
    medical_card_expiration: '',
    measurement_system: null,
    dst: null,
    first_day: null,
    compliance_mode: null,
    motion_treshold: null,
    cargo_type: [],
    restart: null,
    rest_break: null,
    short_haul: false,
    personal_conveyance: false,
    adverse_conditions: false,
    unlimited_documents: false,
    unlimited_trailers: false,
    yard_move: false,
    exempt_driver: false,
    exempt_driver_notice: false,
    period_starting_time: '',
    motion_trashhold: '',
    terminal: null,
    driver_group: null,
    password: '',
    carrier: null,
    cdl_state: null,
  });

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['settings', 'terminals', 'driver_groups'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...(!currentCarrier.defaultSavedCarrier ? currentCarrier?.settings : {}),
      cargo_type: form.getFieldValue('cargo_type'),
      driver_group: currentCarrier?.defaultSavedCarrier
        ? form.getFieldValue('driver_group')
        : null,
      status: 1,
    });
  }, [currentCarrier]);

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      // cdl_expiration: values.cdl_expiration.split('T')[0],
      // medical_card_expiration: values.medical_card_expiration.split('T')[0],
      company: user.company.id,
      cdl_state: `${values.cdl_state}`,
      offices: [...user.offices].map((office) => office.id),
      documents: [
        ...(values?.documents_MC?.length > 0
          ? values?.documents_MC?.map((doc: any) => {
              return {
                type: getDocumentByType(doc.fileType),
                file: doc.originFileObj,
                driver: params.driverid,
              };
            })
          : []),
        ...(values?.documents_CDL?.length > 0
          ? values?.documents_CDL?.map((doc: any) => {
              return {
                type: getDocumentByType(doc.fileType),
                file: doc.originFileObj,
                driver: params.driverid,
              };
            })
          : []),
      ],
      documents_MC: undefined,
      documents_CDL: undefined,
    };
    dispatch(
      createDriverReq({
        values: data,
        onSuccess: () => {
          form.setFieldsValue(initialValues);
          setCurrentCarrier({});
        },
      }),
    );
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  return (
    <>
      {checkPermission(AllPermissionsType.DRIVER_CREATE) ? (
        <Row style={{ height: '100%' }}>
          {/* <Graph /> */}
          {loading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                minHeight: 600,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </div>
          ) : (
            <MainContainer half>
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                >
                  {carrierForm({ create: true }).map(
                    (field: any, i: number) => {
                      if (field.type === InputType.ADD_DYNAMIC) {
                        return (
                          <CommonInput
                            currentIndex={currentIndex}
                            fields={fields}
                            key={i}
                            setCurrentIndex={setCurrentIndex}
                            {...field}
                            form={form}
                          />
                        );
                      }
                      return (
                        <CommonInput
                          key={i}
                          {...field}
                          form={form}
                          isDriverCreate={true}
                        />
                      );
                    },
                  )}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '85px', marginRight: 12 }}
                    >
                      Submit
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </MainContainer>
          )}
        </Row>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
