import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form, Button, Modal } from 'antd';
import { CommonInput } from '../../common/inputs';
import { createLogForm } from './log-form';
import { InputType } from '../../../constants/inputs';

import {
  createDriverLogReq,
  getDriverLogListReq,
} from '../../../actions/driver_log';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { getTzValue } from '../../../utils/utils';
import { API } from '../../../api/API';

dayjs.extend(buddhistEra);

export const CopyEventModal = ({
  record,
  children,
}: {
  record: any;
  children: ReactNode;
}) => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const device = driverLog?.logForms?.[0]?.unit?.device?.id;
  const tz = getTzValue(tzValue);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const driverConfigLog = useSelector(
    (state: any) => state?.log?.driverConfigLog,
  );
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialValues, setInitialValues] = useState<any>({
    start_time: Date.now(),
    end_time: '',
    identificator: '',
    vehicle: '',
    device: '',
    codriver: '',
    trailer: '',
    event_type: '',
    event_code: '',
    status: '',
    note: '',
    event: '',
    timestamp: '',
    shipping_doc: '',
    record_origin: '',
    record_status: '',
    total_miles: '',
    total_hours: '',
    location: '',
    latitude: '',
    longitude: '',
  });
  useEffect(() => {
    if (driverConfigLog?.data) {
      const data = driverConfigLog?.data;
      const values = {
        start_time: Date.now(),
        end_time: '',
        identificator: record.id,
        vehicle: data?.vehicle?.id,
        device: data?.device?.id,
        codriver: data?.codriver?.id,
        trailer: data?.trailer,
        event_type: record?.event_type,
        event_code: record?.event_code,
        status: record?.trailer,
        annotations: record?.note,
        event: `${record?.event_type}${record?.event_code}`,
        timestamp: dayjs(record?.timestamp * 1000)
          .tz(tz)
          .valueOf(),
        shipping_doc: data?.shipping_doc,
        record_origin: '',
        record_status: '#',
        total_miles: record?.total_miles,
        total_hours: record?.total_hours?.toFixed(1),
        location: record?.location,
        latitude: record?.latitude,
        longitude: record?.longitude,
      };
      setInitialValues(values);
      form.setFieldsValue(values);
    }
  }, [driverConfigLog]);
  function extractTime(dateTime: string): string {
    return dateTime?.split(' ')[1];
  }
  useEffect(() => {
    const time = extractTime(record?.datetime);

    form.setFieldValue('time', time);
  }, [record.timestamp]);

  const handleSubmit = async (values: any) => {
    const hours = values.time.slice(0, 2);
    const minutes = values.time.slice(3, 5);
    const seconds = values.time.slice(6, 8);

    const data = {
      driver: params.driverid,
      event_type: +values?.event[0],
      event_code: +values?.event[1],
      record_origin: values?.record_origin,
      record_status: 1,
      vehicle: values?.vehicle,
      total_miles: values?.total_miles,
      total_hours: values?.total_hours,
      latitude: values?.latitude,
      longitude: values?.longitude,
      codriver: values?.codriver,
      shipping_doc: values?.shipping_doc,
      trailer: values?.trailer?.length != 0 ? values?.trailer : null,
      date: dayjs(values.timestamp).format('YYYY-MM-DD'),
      time: `${hours}:${minutes}:${seconds}`,
      location: values?.location,
      note: values?.note?.length,
      device,
    };

    dispatch(
      createDriverLogReq({
        values: data,
        onSuccess: () => {
          form.setFieldsValue(initialValues);
          dispatch(
            getDriverLogListReq({
              queryParams: {
                with: [
                  'driver_groups',
                  'vehicles',
                  'drivers',
                  'vehicle',
                  'driver',
                  'codriver',
                ],
              },
              driverid: params?.driverid,
              date: driverLogDate,
            }),
          );
          handleCancel();
        },
      }),
    );
  };
  const { getFormReq } = API;
  const getCreateForm = async () => {
    const time = form.getFieldValue('time');
    const day = dayjs(form.getFieldValue('timestamp')).tz(tz).startOf('day');
    const hours = time.slice(0, 2);
    const minutes = time.slice(3, 5);
    const seconds = time.slice(6, 8);

    const timestamp = day
      .add(hours, 'hour')
      .add(minutes, 'minute')
      .add(seconds, 'second')
      .add(1, 'day')
      .valueOf();
    const driverConfigLog = await getFormReq({
      id: params.driverid || '',
      timestamp: timestamp / 1000,
    });
    setTimeout(() => {
      if (driverConfigLog) {
        const data = {
          vehicle: driverConfigLog?.vehicle?.id,
          codriver: driverConfigLog?.codriver?.id,
          shipping_doc: driverConfigLog?.shipping_doc,
          trailer: driverConfigLog?.trailer,
          device: driverConfigLog?.unit?.device?.id,
          note: driverConfigLog?.notes,
          unit: driverConfigLog?.unit?.id,
        };
        form.setFieldsValue(data);
      }
    }, 200);
  };

  const [hourError, setHourError] = useState(0);
  const changeValue = (e: any) => {
    if (e.nativeEvent.target.id === 'time') {
      let value: string = form.getFieldValue('time');
      if (value) {
        value = value.replace(/\D/g, '');
        const hours = parseInt(value.slice(0, 2), 10);
        if (hours < 0 || hours > 24) {
          setHourError(1);
          value = value.slice(0, 2);
        } else {
          setHourError(0);
        }
        const minutes = parseInt(value.slice(2, 4), 10);
        if (minutes < 0 || minutes > 59) {
          setHourError(1);
          value = value.slice(0, 4);
        } else {
          setHourError(0);
        }
        const seconds = parseInt(value.slice(4, 6), 10);
        if (seconds < 0 || seconds > 59) {
          setHourError(1);
          value = value.slice(0, 6);
        } else {
          setHourError(0);
        }
        value = value.slice(0, 6);
        if (value.length === 6) {
          getCreateForm();
        }
        value = value.replace(/(..)(?!$)/g, '$1:');
        form.setFieldValue('time', value);
      }
    }
  };
  const datePickerChange = (value: any) => {
    form.setFieldValue('time', '');
    form.setFieldValue('time', '');
    form.setFieldValue('vehicle', '');
    form.setFieldValue('codriver', '');
    form.setFieldValue('shipping_doc', '');
    form.setFieldValue('trailer', '');
    form.setFieldValue('device', '');
    form.setFieldValue('note', '');
    form.setFieldValue('unit', '');
    setHourError(0);
  };

  useEffect(() => {
    const vehicle = driverConfigLog?.unit?.vehicle?.id;
    const shipping_doc = driverConfigLog?.shipping_doc;
    const device = driverConfigLog?.unit?.device?.id;
    const trailer = driverConfigLog?.trailer;

    setInitialValues({
      ...initialValues,
      vehicle,
      shipping_doc,
      device,
      trailer,
    });
  }, []);
  return (
    <>
      <div onClick={showModal}>{children}</div>
      <Modal
        title="Create Event"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          onChange={changeValue}
        >
          {createLogForm({ datePickerChange }).map((field: any, i: number) => {
            if (field.type === InputType.ADD_DYNAMIC) {
              return (
                <CommonInput
                    currentIndex={currentIndex}
                    fields={fields}
                    key={i}
                    setCurrentIndex={setCurrentIndex}
                    {...field}
                    form={form}
                  />
                // prettier-ignore
              );
            }

            // prettier-ignore

            return (
              <CommonInput
                key={i}
                {...field}
                errorVisible={hourError}
                form={form}
              />
            );
          })}
          <Form.Item style={{ width: '100%', display: 'flex' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="orange"
              style={{ width: '65px', marginRight: 12 }}
            >
              Save
            </Button>
            <Button
              className="grey"
              style={{ width: '85px', marginRight: 12 }}
              onClick={() => {
                // form.setFieldsValue(initialValues);
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
