import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { DriverLogs } from './LogTabs/Chart/interface/ChartInterface';
import { chartFromDateToTimeStamp } from './LogTabs/Chart/utils/ChartDateForrmatter';
import { getTzValue } from '../../../utils/utils';

export const LogTab = () => {
  const logs: DriverLogs = useSelector((state: any) => state.driverLog.logList);
  const logForms = useSelector((state: any) => state.driverLog.logForms);
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const [data, setData] = useState<any>([]);
  const inittialTime = chartFromDateToTimeStamp(driverLogDate, tz);
  const columns: ColumnsType<any> = [
    {
      title: 'Start Time',
      key: 'date',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>
            {dayjs(record.timestamp * 1000)
              .tz(tz)
              .format('MM/DD/YYYY HH:mm:ss')}
          </div>
        );
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      key: 'vehicle',
      dataIndex: 'vehicale',
      render: (name, record, index) => {
        return <>{record.vehicle}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'VIN',
      key: 'vin',
      dataIndex: 'vin',
      render: (name, record, index) => {
        return <>{record.vin}</>;
      },
      width: '29%',
      ellipsis: true,
    },
    {
      title: 'ELD',
      key: 'eld',
      dataIndex: 'eld',
      render: (name, record, index) => {
        return <>{record.eld}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Trailers',
      key: 'trailer',
      dataIndex: 'trailer',
      render: (name, record, index) => {
        return <>{record.trailer}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'From',
      key: 'from',
      dataIndex: 'from',
      render: (name, record, index) => {
        return <>{record.from}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'To',
      key: 'to',
      dataIndex: 'to',
      render: (name, record, index) => {
        return <>{record.to}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Start Odometer',
      key: 'startOdometr',
      dataIndex: 'startOdometr',
      render: (name, record, index) => {
        return <>{record.startOdometr}</>;
      },
      width: '29%',
      ellipsis: true,
    },
    {
      title: 'End Odometer',
      key: 'endOdometr',
      dataIndex: 'endOdometr',
      render: (name, record, index) => {
        return <>{record.endOdometr}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Miles Today',
      key: 'miles',
      dataIndex: 'miles',
      render: (name, record, index) => {
        return <>{record.miles}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Start EH',
      key: 'startEh',
      dataIndex: 'startEh',
      render: (name, record, index) => {
        return <>{record.startEh}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'End EH',
      key: 'endEh',
      dataIndex: 'endEh',
      render: (name, record, index) => {
        return <>{record.endEh}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'EH Today',
      key: 'ehtoday',
      dataIndex: 'ehtoday',
      render: (name, record, index) => {
        return <>{record.ehtoday}</>;
      },
      width: '29%',
      ellipsis: true,
    },
    {
      title: 'Shipping Docs',
      key: 'shipping',
      dataIndex: 'shipping',
      render: (name, record, index) => {
        return <>{record.shipping}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Co-Driver',
      key: 'codriver',
      dataIndex: 'codriver',
      render: (name, record, index) => {
        return <>{record?.codriver ? record?.codriver : ''}</>;
      },
      width: '24%',
      ellipsis: true,
    },
  ];

  useEffect(() => {
    const dataTest = logForms?.map((form: any) => {
      const firstLog = logs?.[0];
      const lastLog = logs.length != 1 ? logs?.[logs.length - 1] : null;
      const lastLogHours = lastLog ? lastLog.duration : '';
      const summHours = `${lastLog?.duration && firstLog.duration ? lastLog?.duration + firstLog?.duration : ''}`;
      const data = {
        timestamp: form?.timestamp,
        vehicle: form?.vehicle?.identificator,
        vin: form?.vehicle?.id,
        eld: form?.unit?.device?.identificator,
        trailer: form?.trailer,
        from: firstLog?.location,
        to: lastLog?.location,
        startOdometr: firstLog?.total_miles,
        endOdometr: lastLog?.total_miles,
        startEh: firstLog?.duration,
        endEh: lastLogHours ? lastLogHours : '',
        codriver: form?.codriver
          ? form?.codriver?.first_name +
            ' ' +
            form?.codriver?.last_name +
            ' ' +
            `(${form?.codriver?.username})`
          : '',
        miles: `${lastLog ? lastLog?.total_miles - firstLog?.total_miles : ''}`,
        ehtoday: summHours,
        shipping: form?.shipping_doc,
      };
      return data;
    });
    setData(dataTest);
  }, [logs, logForms]);

  return (
    <>
      <Table
        columns={columns.slice(0, 5)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
        className="table-custom-form"
        bordered
      />
      <Table
        columns={columns.slice(5, 10)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
        className="table-custom-form-middle"
        bordered
      />
      <Table
        columns={columns.slice(10, 15)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
        className="table-custom-form-bottom"
        bordered
      />
    </>
  );
};
