import { InputSelectV2 } from '../../common/doubleinput';
import styles from './RecordPage.module.css';
import { InputTextArea } from '../../common/doubleinput/InputTextArea';
import React from 'react';
import { DriverDocumentsList } from '../driver/fields/DriverDocumentsList';
import { TextInputDot } from '../../common/doubleinput/InputTextDot';
import RecordErrorField from './RecordErrorField';
import { DOTDocumentsList } from '../driver/fields/DotDocumentsList';

const InputsBlock = ({
  title,
  add,
  blocks,
  addClick,
  violationsValue,
  reports,
}: IInputsBlockProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleBlock}>
        <h1 className={`${styles.titleBlockH1} ubuntu`}>{title}</h1>
        {add && (
          <div
            className={`${styles.add} ${violationsValue === 1 && 'orange'} ubuntu`}
            onClick={addClick}
            style={{ cursor: 'default' }}
          >
            <span
              className={`icon-fi-rr-plus ubuntu ${violationsValue === 1 && 'orange'}`}
              style={{ width: 'fit-content' }}
            />
            Add Inspection
          </div>
        )}
      </div>
      <div className={styles.inputsBlock}>
        {blocks.map((fileds, index) => (
          <React.Fragment key={index}>
            <div className={styles.inputsBlockBody}>
              <div className={styles.inputsBlockTitle}>
                <h2 className={styles.inputsBlockTitleH2}>{fileds.title}</h2>
              </div>
              <div className={styles.inputsBlockFileds}>
                {fileds.input.map((input, index) => (
                  <React.Fragment key={index}>
                    {input.inputType === 'text' && (
                      <div
                        className={`${input.type === 'single' && `${styles.single}`}`}
                      >
                        <TextInputDot {...input} />
                      </div>
                    )}
                    {input.inputType === 'error' && (
                      <div
                        className={`${input.type === 'single' && `${styles.single}`}`}
                      >
                        <RecordErrorField onClick={input.onClick} {...input} />
                      </div>
                    )}
                    {input.inputType === 'select' && (
                      <div
                        className={`${input.type === 'single' && `${styles.single}`}`}
                      >
                        <InputSelectV2 {...input} />
                      </div>
                    )}
                    {input.inputType === 'upload' && (
                      <div
                        className={`${input.type === 'single' && `${styles.single}`}`}
                      >
                        <DOTDocumentsList reports={reports} {...input} />
                      </div>
                    )}
                    {input.inputType === 'notice' && (
                      <div
                        className={`${input.type === 'single' && `${styles.single}`}`}
                      >
                        <InputTextArea {...input} />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InputsBlock;

export interface IInputsBlockProps {
  title: string;
  add?: boolean;
  addClick?: () => void;
  blocks: IInputsBlock[];
  violationsValue?: number;
  reports?: string[];
}

interface IInputsBlock {
  input: IInput[];
  title: string;
}
export interface IInput {
  inputType: 'text' | 'select' | 'error' | 'upload' | 'notice';
  name: string;
  label: string;
  placeholder: string;
  hasFeedback: boolean;
  title: string;
  onChange?: (value: number) => void;
  onClick?: () => void;
  disabled?: boolean;
  value?: string;
  span: number;
  width: string;
  type: 'single' | 'double';
  options?: {
    key: number;
    value: string;
  }[];
}
