import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCarriersListReq } from '../../../actions/carrier';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { carrierForm } from './vehicle-form';
import { Graph } from '../../common/graph/Graph';
import { InputType } from '../../../constants/inputs';
import { getDocumentByType } from './constant';

import {
  createVehicleReq,
  setCurrentVehicleCarrier,
} from '../../../actions/vehicle';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import MainContainer from '../../layout/MainContainer';
import { cleanObject } from '../../../utils/cleanObject';

function buildFormData(formData: any, data: any, parentKey?: any) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export const VehicleCreatePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, vehicle, currentCarrier } = useSelector(
    (state: any) => state.vehicle,
  );
  const navigate = useNavigate();
  const { loading: carrierLoading, carrierList } = useSelector(
    (state: any) => state.carrier,
  );
  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [initialValues, setInitialValues] = useState({
    identificator: '',
    vin: '',
    make: null,
    model: '',
    fuel_type: null,
    carrier: null,
    status: 1,
    notes: '',
    license_plate: null,
    license_issuing: '',
    license_expiration: '',
    year: '',
  });

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['settings', 'terminals', 'driver_groups'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
    });
    form.setFieldValue('status', 1);
  }, [currentCarrier]);

  const handleSubmit = async (values: any) => {
    const data = cleanObject({
      ...values,
    });
    dispatch(
      createVehicleReq({
        values: data,
        onSuccess: () => {
          form.setFieldsValue(initialValues);
          setCurrentVehicleCarrier({});
        },
      }),
    );
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_CREATE) ? (
        <MainContainer half>
          <Row style={{ height: '100%' }}>
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                >
                  {carrierForm({ create: true }).map(
                    (field: any, i: number) => {
                      if (field.type === InputType.ADD_DYNAMIC) {
                        return (
                          <CommonInput
                            currentIndex={currentIndex}
                            fields={fields}
                            key={i}
                            setCurrentIndex={setCurrentIndex}
                            {...field}
                            form={form}
                          />
                        );
                      }
                      return <CommonInput key={i} {...field} form={form} />;
                    },
                  )}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '85px', marginRight: 12 }}
                    >
                      Submit
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
