import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form, Button, Modal } from 'antd';
import { CommonInput } from '../../common/inputs';
import { createLogForm } from './log-form';
import { InputType } from '../../../constants/inputs';

import {
  createDriverLogReq,
  getDriverLogListReq,
} from '../../../actions/driver_log';
import dayjs from 'dayjs';
import { getTzValue } from '../../../utils/utils';
import { getLogForm } from '../../../actions';
import { API } from '../../../api/API';

export const EditDriverLogModal = (props: any) => {
  const [form] = Form.useForm();
  const params = useParams();
  const driverLog = useSelector((state: any) => state.driverLog);
  const device = driverLog?.logForms?.[0]?.unit?.device?.id;
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [unit, setUnit] = useState('');

  const [initialValues, setInitialValues] = useState({
    start_time: Date.now(),
    end_time: '',
    id: props?.log?.id,
    vehicle: props?.log?.unit_daily?.vehicle?.id,
    device: props?.log?.unit_daily?.device?.id,
    codriver: props?.log?.codriver?.id,
    trailer: props?.log?.trailer,
    status: null,
    annotations: props?.log?.note,
    timestamp: dayjs(props?.log.timestamp * 1000).tz(tz),
    shipping_doc: props?.log?.shipping_doc,
    record_status: 1,
    total_miles: props?.log?.total_miles,
    total_hours: props?.log?.total_hours?.toFixed(1),
    latitude: props?.log?.latitude,
    longitude: props?.log?.longitude,
    event_type: '',
    event_code: '',
    event: `${props?.log?.event_type}${props?.log?.event_code}`,
    record_origin: `${props?.log?.record_origin}`,
    location: props?.log?.location,
    identificator: props?.log?.id,
    time: '',
  });

  const [hourError, setHourError] = useState(0);
  const { getFormReq } = API;
  const getCreateForm = async (timestampInit?: number) => {
    const time = form.getFieldValue('time');
    const day = dayjs(form.getFieldValue('timestamp')).tz(tz).startOf('day');
    const hours = time.slice(0, 2);
    const minutes = time.slice(3, 5);
    const seconds = time.slice(6, 8);

    const timestamp = day
      .add(hours, 'hour')
      .add(minutes, 'minute')
      .add(seconds, 'second')
      .add(1, 'day')
      .valueOf();
    const driverConfigLog = await getFormReq({
      id: params.driverid || '',
      timestamp: timestampInit || timestamp / 1000,
    });
    setTimeout(() => {
      if (driverConfigLog) {
        const data = {
          vehicle: driverConfigLog?.vehicle?.id,
          codriver: driverConfigLog?.codriver?.id,
          shipping_doc: driverConfigLog?.shipping_doc,
          trailer: driverConfigLog?.trailer,
          device: driverConfigLog?.unit?.device?.id,
          unit: driverConfigLog?.unit?.id,
        };
        setUnit(driverConfigLog?.unit?.device?.id);
        form.setFieldsValue(data);
      }
    }, 200);
  };
  useEffect(() => {
    isModalOpen && getCreateForm(props.log.timestamp);
  }, [isModalOpen]);
  const changeValue = (e: any) => {
    if (e.nativeEvent.target.id === 'time') {
      let value: string = form.getFieldValue('time');
      if (value) {
        value = value.replace(/\D/g, '');
        const hours = parseInt(value.slice(0, 2), 10);
        if (hours < 0 || hours > 24) {
          setHourError(1);
          value = value.slice(0, 2);
        } else {
          setHourError(0);
        }
        const minutes = parseInt(value.slice(2, 4), 10);
        if (minutes < 0 || minutes > 59) {
          setHourError(1);
          value = value.slice(0, 4);
        } else {
          setHourError(0);
        }
        const seconds = parseInt(value.slice(4, 6), 10);
        if (seconds < 0 || seconds > 59) {
          setHourError(1);
          value = value.slice(0, 6);
        } else {
          setHourError(0);
        }
        value = value.slice(0, 6);
        if (value.length === 6) {
          getCreateForm();
        }
        value = value.replace(/(..)(?!$)/g, '$1:');
        form.setFieldValue('time', value);
      }
    }
  };
  const handleSubmit = async (values: any) => {
    const hours = values.time.slice(0, 2);
    const minutes = values.time.slice(3, 5);
    const seconds = values.time.slice(6, 8);
    const test: string[] = values?.trailer;
    const trailer =
      typeof values?.trailer === 'string'
        ? values?.trailer
        : (test?.length != 0 && test?.join(',')) || '';
    const data = {
      driver: params.driverid,
      event_type: +values?.event[0],
      event_code: +values?.event[1],
      record_origin: values?.record_origin,
      record_status: 1,
      vehicle: values?.vehicle,
      total_miles: values?.total_miles,
      total_hours: values?.total_hours,
      latitude: values?.latitude,
      longitude: values?.longitude,
      codriver: values?.codriver || null,
      shipping_doc: values?.shipping_doc,
      trailer,
      date: dayjs(values.timestamp).format('YYYY-MM-DD'),
      time: `${hours}:${minutes}:${seconds}`,
      location: values?.location,
      note: values?.note || null,
      id: props.log.id,
      device,
    };

    dispatch(
      createDriverLogReq({
        values: data,
        onSuccess: () => {
          form.setFieldsValue(initialValues);

          dispatch(
            getDriverLogListReq({
              queryParams: {
                with: [
                  'driver_groups',
                  'vehicles',
                  'drivers',
                  'vehicle',
                  'driver',
                  'codriver',
                ],
              },
              driverid: params?.driverid,
              date: driverLogDate,
            }),
          );
          handleCancel();
        },
      }),
    );
  };

  const datePickerChange = (value: any) => {
    form.setFieldValue('time', '');
    form.setFieldValue('time', '');
    form.setFieldValue('vehicle', '');
    form.setFieldValue('codriver', '');
    form.setFieldValue('shipping_doc', '');
    form.setFieldValue('trailer', '');
    form.setFieldValue('device', '');
    form.setFieldValue('note', '');
    form.setFieldValue('unit', '');
    setHourError(0);
  };

  function extractTime(dateTime: string): string {
    return dateTime?.split(' ')[1];
  }

  useEffect(() => {
    const time = extractTime(props?.log?.datetime);

    form.setFieldValue('time', time);
  }, [props.log]);
  return (
    <>
      <div onClick={showModal}>{props?.children}</div>

      <Modal
        title="Edit Event"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          onChange={changeValue}
        >
          {createLogForm(datePickerChange).map((field: any, i: number) => {
            if (field.type === InputType.ADD_DYNAMIC) {
              return (
                <CommonInput
                  currentIndex={currentIndex}
                  fields={fields}
                  key={i}
                  setCurrentIndex={setCurrentIndex}
                  {...field}
                  form={form}
                  errorVisible={hourError}
                />
              );
            }
            return <CommonInput key={i} {...field} form={form} />;
          })}
          <Form.Item style={{ width: '100%', display: 'flex' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="orange"
              style={{ width: '65px', marginRight: 12 }}
            >
              Save
            </Button>
            <Button
              className="grey"
              style={{ width: '85px', marginRight: 12 }}
              onClick={() => {
                // form.setFieldsValue(initialValues);
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
